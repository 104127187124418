<template>
  <BaseHeader />
  <full-page ref="fullpage" id="fullpage" :options="pageOptions">
    <AboutMeSection />
    <SkillsSection />
    <ProjectsSection />
    <ContactSection />
  </full-page>
</template>

<script>
// import $ from "jquery";
import BaseHeader from "@/components/BaseHeader.vue";
import AboutMeSection from "@/views/sections/AboutMeSection.vue";
import SkillsSection from "@/views/sections/SkillsSection.vue";
import ProjectsSection from '@/views/sections/ProjectsSection.vue';
import ContactSection from '@/views/sections/ContactSection.vue';

export default {
  name: "HomeView",
  components: {
    BaseHeader,
    AboutMeSection,
    SkillsSection,
    ProjectsSection,
    ContactSection
  },
  data () {
    return {
      pageOptions: {
        scrollBar: true,
        responsiveWidth: 400,
        navigation: true,
        navigationTooltips: ["home", "about", "portfolio", "contact"],
        anchors: ["home", "about", "portfolio", "contact"],
        fitToSection: false,
        afterLoad: this.afterLoad
      },
    };
  },
  methods: {
    afterLoad () {
      console.log("After loading");
    },
  },
};
</script>
