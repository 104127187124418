<template>
  <div class="nav-header">
    <div class="nav-brand">
    </div>
    <div class="header-links">
      <ul>
        <li data-menuanchor="fourthPage"><a href="#contact">Contact</a></li>
        <li data-menuanchor="thirdPage"><a href="#projects">Portfolio</a></li>
        <li data-menuanchor="secondPage"><a href="#skills">About</a></li>
      </ul>
    </div>
  </div>
</template> 

<script>
export default {
  name: "BaseHeader",
  components: {
    // BaseButton,
  },
};
</script>

<style lang="scss" scoped>
.nav-brand {
  position: fixed;
  left: 0;
  z-index: 600;
  padding: 10px;

  img {
    width: 50px;
  }
}

.header-links {
  position: fixed;
  width: 100%;
  z-index: 500;
}

.header-links li {
  color: white;
  display: inline;
  float: right;
  padding: 15px;
  font-size: 16px;
  text-transform: uppercase;
}

.header-links a {
  color: #757575;
  -webkit-transition: all 0.2s ease-in;
}

.header-links a:hover {
  color: #ff6363 !important;
}

</style>