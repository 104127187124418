<template>
    <section class="section" data-anchor="skills">
        <h1>Skills</h1>
    </section>
</template>

<script>
export default {
  name: "SkillsSection",
  components: {
  }
};
</script>

<style lang="scss" scoped>

</style>