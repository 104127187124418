<template>
  <section class="section aboutme" data-anchor="aboutme">
    <div class="coming_soon">
      <div class="info_container">
        <h1>Coming Soon</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutMeSection",
  components: {},
};
</script>

<style lang="scss" scoped>
.aboutme {
  background-image: url("~@/assets/bg-main.jpg");
}

.coming_soon {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/bg.jpg");
    background-size: cover;
    }

    .info_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #FFF;
    }

    h1 {
        font-family: 'Dancing Script', cursive;
        font-size: 100px;
        margin-bottom: 30px;
        text-align: center;
        animation: action 1s infinite  alternate;
    }

    @-webkit-keyframes action {
        0% { transform: translateY(0); scale: 0.9; }
        100% { transform: translateY(-50px); scale: 1; }
    }

    @keyframes action {
        0% { transform: translateY(0); scale: 0.9; }
        100% { transform: translateY(-50px); scale: 1; }
    }
</style>