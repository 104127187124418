<template>
  <div class="app">
    <HomeView />
  </div>
</template>

<script>
import HomeView from '@/views/HomeView.vue'
export default {
  name: 'App',
  components: {
    HomeView
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

html,
body,
#app,
.app {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: "Raleway", sans-serif !important;
}

a {
    text-decoration: none !important;
}
</style>
