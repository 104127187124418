<template>
    <section class="section" data-anchor="contact">
        <h1>Contact</h1>
    </section>
</template>

<script>
export default {
  name: "ContactSection",
  components: {
  }
};
</script>

<style lang="scss" scoped>

</style>